@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	font-family: 'font-luxia';
}

::-webkit-scrollbar {
	width: 0;
	display: none;
}
::-webkit-scrollbar-track {
	background-color: rgb(255, 255, 255);
}
::-webkit-scrollbar-thumb {
	background-color: rgb(39, 37, 86);
	/* border-radius: 50px; */
}

@media (max-width: 840px) {
	::-webkit-scrollbar {
		display: none;
	}
}
.toastBody {
	color: #10171d; /* #10171D */
	font-size: 1.3rem !important;
}
